// JavaScript Document

// Scripts written by Jacob Bearce @ Weblinx, Inc.

import Swiper, { Autoplay, EffectFade, Navigation } from "swiper";

const CARDS = document.querySelector(".swiper-container--cards");

// init swiper
if (CARDS) {
    new Swiper (CARDS, {
        modules: [Navigation],
        navigation: {
            nextEl: "#cards-next",
            prevEl: "#cards-prev",
        },
        spaceBetween: 40,
        speed: 150,
        breakpoints: {
            768: {
                slidesPerView: 2,
            },
            1024: {
                slidesPerView: 3,
            },
        },
    });
}

const POLAROID = document.querySelector(".swiper-container--polaroid");

// init swiper
if (POLAROID) {
    new Swiper (POLAROID, {
        modules: [Autoplay, EffectFade],
        autoplay: {
            delay: 7500,
        },
        effect: "fade",
        loop: true,
        speed: 150,
    });
}
